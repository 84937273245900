import React from "react";

function GiroPayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="22"
      viewBox="0 0 46.511 20.001"
    >
      <g fillRule="evenodd" transform="translate(-17.717 -22.137)">
        <path
          fill="#000268"
          d="M17.717 25.726a3.6 3.6 0 013.606-3.589h39.3a3.6 3.6 0 013.606 3.589V38.55a3.6 3.6 0 01-3.606 3.588h-39.3a3.6 3.6 0 01-3.606-3.588V25.726z"
        ></path>
        <path
          fill="#fff"
          d="M45.9 52.357v12.58a2.249 2.249 0 002.255 2.242h20.593V50.114H48.159a2.249 2.249 0 00-2.259 2.243zm28.164 6.227c0 .8-.4 1.351-1.052 1.351a1.179 1.179 0 01-1.062-1.284c0-.753.425-1.313 1.062-1.313a1.113 1.113 0 011.056 1.246zm-3.851 4.594h1.737v-2.732h.019a1.746 1.746 0 001.594.82c1.5 0 2.3-1.235 2.3-2.721a2.346 2.346 0 00-2.152-2.539 1.983 1.983 0 00-1.873 1.024h-.019v-.907h-1.6zm8.018-3.619c0-.482.463-.665 1.052-.665.26 0 .512.018.733.029a1.132 1.132 0 01-1.072 1.187c-.401-.001-.709-.203-.709-.552zm3.5 1.593a6.194 6.194 0 01-.1-1.187v-1.873c0-1.534-1.109-2.085-2.393-2.085a5.191 5.191 0 00-2.007.358l.03 1.178a3.257 3.257 0 011.6-.377c.626 0 1.138.184 1.148.869a5.069 5.069 0 00-.811-.068c-.926 0-2.6.184-2.6 1.718 0 1.091.888 1.582 1.882 1.582a1.723 1.723 0 001.593-.907h.019c0 .261.028.521.039.792zm.782 2.027a5.052 5.052 0 001.081.117c1.593 0 1.968-1.226 2.461-2.491l1.845-4.682h-1.733L85.134 59.4h-.019l-1.081-3.281h-1.872l2.038 5.148a.883.883 0 01-.879.7 2.087 2.087 0 01-.685-.106z"
          transform="translate(-26.707 -26.508)"
        ></path>
        <path
          fill="#ff0007"
          d="M94.317 129.236c0-.685.338-1.255.985-1.255a1.1 1.1 0 011.109 1.187 1.174 1.174 0 01-1.109 1.293c-.521 0-.985-.443-.985-1.225zm3.774-2.471h-1.574v.907H96.5a1.966 1.966 0 00-1.709-1.024c-1.563 0-2.268 1.121-2.268 2.616a2.259 2.259 0 002.238 2.528 1.959 1.959 0 001.69-.859h.019v.271c0 .984-.54 1.457-1.543 1.457a3.457 3.457 0 01-1.7-.415l-.087 1.371a5.839 5.839 0 001.921.319c2.027 0 3.03-.666 3.03-2.732zm2.936-2.094h-1.738v1.274h1.737zm-1.737 7.122h1.737v-5.028H99.29zm6.574-5.086a3.757 3.757 0 00-.588-.058 1.552 1.552 0 00-1.486 1.043h-.019v-.926h-1.582v5.028h1.737v-2.124c0-.985.454-1.573 1.265-1.573a2.028 2.028 0 01.588.057zm2.991 3.986c-.8 0-1.129-.666-1.129-1.409s.328-1.419 1.129-1.419 1.13.666 1.13 1.419-.329 1.409-1.131 1.409zm0 1.216a2.645 2.645 0 10-2.923-2.624 2.634 2.634 0 002.922 2.623z"
          transform="translate(-70.877 -97.15)"
        ></path>
      </g>
    </svg>
  );
}

export default GiroPayIcon;
