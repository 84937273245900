import React from "react";

function SepaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="24"
      viewBox="0 0 32 20"
    >
      <g data-name="Group 1483">
        <path fill="none" d="M0 0H32V20H0z" data-name="Rectangle 878"></path>
        <g transform="translate(.586 5.787)">
          <path
            fill="#10298e"
            d="M6.848 3.743H4.739a1.353 1.353 0 00-.123-.7c-.123-.177-.469-.263-1.036-.263a2.527 2.527 0 00-1.093.152.707.707 0 00-.267.668.655.655 0 00.234.6 1.012 1.012 0 00.448.119l.427.033c.913.062 1.48.1 1.71.127a2.594 2.594 0 011.566.575A1.529 1.529 0 017.062 6a6.411 6.411 0 01.038.8 4.231 4.231 0 01-.19 1.485 1.913 1.913 0 01-1.583 1.093 9.112 9.112 0 01-1.574.1 9.839 9.839 0 01-2.47-.21A1.62 1.62 0 01.152 8.215 4.915 4.915 0 010 6.748h2.109v.177c0 .366.107.6.317.707a1.227 1.227 0 00.526.115h.777a2.763 2.763 0 00.756-.062.631.631 0 00.382-.37 1.348 1.348 0 00.049-.407q0-.555-.407-.678A11.858 11.858 0 003.1 6.1c-.675-.051-1.143-.1-1.406-.142a1.872 1.872 0 01-1.381-.8A3.159 3.159 0 01.008 3.6a3.984 3.984 0 01.164-1.27A1.38 1.38 0 01.7 1.61a2.663 2.663 0 011.365-.448c.46-.041.974-.062 1.533-.062a8.073 8.073 0 011.891.152 1.873 1.873 0 011.376 2.072c0 .095 0 .234-.016.419"
            transform="translate(0 -1.055)"
          ></path>
          <path
            fill="#10298e"
            d="M392.3 8.426V0h4.226a4.546 4.546 0 011.324.144 2.052 2.052 0 011.4 1.336 5.148 5.148 0 01.185 1.595 5.906 5.906 0 01-.206 1.841 1.894 1.894 0 01-1.669 1.274c-.1.016-.522.033-1.266.045l-.378.012h-1.348v2.179H392.3zm2.269-4.126h1.414a2.568 2.568 0 00.818-.1.6.6 0 00.267-.444 3.514 3.514 0 00.041-.658 2.687 2.687 0 00-.086-.793.681.681 0 00-.584-.444 4.718 4.718 0 00-.44-.012h-1.43z"
            transform="translate(-376.175)"
          ></path>
          <path
            fill="#10298e"
            d="M548.588 6.967h-3.034l-.407 1.459H542.8L545.336 0h3.42l2.561 8.426h-2.3zm-.452-1.64l-1.06-3.654-1.032 3.654z"
            transform="translate(-520.488)"
          ></path>
          <path
            fill="#ffbe00"
            d="M185.407 2.909a2.986 2.986 0 012.318 1.106l.489-1.048a4.3 4.3 0 00-2.955-1.167 4.245 4.245 0 00-3.942 2.606h-.954l-.563 1.2h1.225c-.008.111-.016.222-.016.337 0 .136.008.275.021.411h-.617l-.563 1.208h1.492a4.3 4.3 0 006.3 1.817V7.9a2.981 2.981 0 01-4.209.28 2.942 2.942 0 01-.534-.617h3.124l.563-1.208h-4.139a3.224 3.224 0 01-.033-.444 2.515 2.515 0 01.016-.3h4.513l.563-1.2h-4.686a2.989 2.989 0 012.585-1.5"
            transform="translate(-172.409 -1.725)"
          ></path>
          <path
            fill="#10298e"
            d="M184.16 2.129a2.949 2.949 0 012.29 1.093l.037.045.025-.053.488-1.048.012-.025-.021-.016a4.357 4.357 0 00-5.405-.44 4.223 4.223 0 00-1.55 1.891l.033-.025h-.969l-.008.021-.563 1.208-.025.053h1.282l-.037-.041c-.008.123-.016.238-.016.341 0 .14.008.275.021.415l.037-.041h-.637l-.008.021-.563 1.208-.025.049h1.55l-.033-.021a4.339 4.339 0 006.35 1.836l.016-.012V6.991l-.062.074a2.948 2.948 0 01-4.16.275 2.98 2.98 0 01-.526-.608l-.029.058h3.149l.008-.021.563-1.208.025-.049H181.2l.037.033a3.121 3.121 0 01-.033-.44 2.447 2.447 0 01.016-.3l-.037.033h4.538l.008-.021.559-1.213.025-.053h-4.743l.033.053a2.968 2.968 0 012.557-1.476m-2.59 1.5h4.686l-.033-.049-.563 1.208.033-.021h-4.546v.033a3.92 3.92 0 00-.016.308 3.3 3.3 0 00.033.452v.033h4.18l-.033-.053-.563 1.208.033-.021h-3.194l.037.058a3.02 3.02 0 004.18.88 3.136 3.136 0 00.625-.543l-.062-.025v1.48l.016-.029a4.334 4.334 0 01-2.372.707 4.214 4.214 0 01-3.88-2.511l-.008-.021h-1.517l.033.053.563-1.208-.033.021h.658v-.041a4.702 4.702 0 01-.021-.407 3.35 3.35 0 01.016-.337v-.041h-1.266l.033.049.563-1.2-.033.021h.978l.008-.021a4.132 4.132 0 011.525-1.858 4.282 4.282 0 015.311.432l-.008-.041-.489 1.048.062-.008a3.022 3.022 0 00-4.965.395l-.033.053z"
            transform="translate(-171.163 -.908)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SepaIcon;
